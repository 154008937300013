import { Injectable } from '@angular/core';
import { Observable, Subscription, tap, throwError } from 'rxjs';
import { GridDataResponse } from 'src/app/data-sheet/data/type/grid/grid-data-response.type';
import { ApiRoutes } from '../../../../common/data/enum/routes.enum';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { ApiService } from '../../../../common/service/api.service';
import { StorageService } from '../../../../common/service/storage.service';
import { Router } from '@angular/router';
import { GridDataIndexResponseType } from '../../../data/type/grid/grid-data-index-response.type';
import { catchError } from 'rxjs/operators';
import { AuthKeysEnum } from '../../../../auth/data/enum/auth-keys.enum';
import { DealDataGridFilterService } from '../../deal-data-grid-filter.service';
import { DataCellService } from '../../data-cell.service';
import { NavigationStore } from '../../../../navigation/service/store/navigation.store';
import { DataPaginationStore } from '../../store/data-pagination.store';
import { PaginationMapperService } from '../pagination-mapper/pagination-mapper.service';
import { DataType } from '../../../../navigation/data/emun/data-type';
import { DealRemoteFilter } from '../../../data/type/filter/deal/deal-remote-filter.type';
import { DealRemoteFilterStore } from '../../store/deal-remote-filter.store';
import { GridSummaryStore } from '../../store/grid-summary.store';
import { UiStore } from '../../../../uicommon/service/ui.store';
import { GridMutatorStore } from '../../mutator/grid-mutator.store';

@Injectable({
  providedIn: 'root'
})
export class DealDataService {
  private dataPaginationStoreSubscription: Subscription = new Subscription();

  constructor(
    private dealDataGridFilterService: DealDataGridFilterService,
    private dataCellService: DataCellService,
    private authorizationService: AuthorizationService,
    private navigationStore: NavigationStore,
    private dataPaginationStoreService: DataPaginationStore,
    private dataPaginationStore: DataPaginationStore,
    private dealRemoteFilterStore: DealRemoteFilterStore,
    private paginationMapperService: PaginationMapperService,
    private storageService: StorageService,
    private gridSummaryStore: GridSummaryStore,
    public readonly gridMutatorStorage: GridMutatorStore,
    private uiStore: UiStore,
    private api: ApiService,
    private router: Router
  ) {
    this.dataPaginationStoreSubscription = this.dataPaginationStore.page$.subscribe(
      () => {
        if (this.navigationStore.navigationState?.dataType === DataType.DEALS)
          this._handleRemotePagination();
      }
    );

    this.dealRemoteFilterStore.dealRemoteSearchTrigger$.subscribe(val => {
      if (
        this.navigationStore.navigationState?.dataType === DataType.DEALS &&
        val
      ) {
        this.dataPaginationStore.page = 1;
        this._handleRemotePagination(true);
      }
    });
  }

  private _handleRemotePagination(reload = false) {
    const page = this.dataPaginationStore.page;
    const activeGrid = this.dealDataGridFilterService.filterStorageService
      .activeGridView;

    let filter = this.dealRemoteFilterStore.dealRemoteFilter;
    filter = { ...filter, mutation: this.gridMutatorStorage.gridMutation };

    const pageMapper = this.paginationMapperService.getRemotePage(
      page,
      this.dataPaginationStore.pageSize,
      this.dataPaginationStore.dataSetSize
    );

    this.dataPaginationStore.relativePage = pageMapper.relativeLocalPage;

    if (
      pageMapper.remotePage !== this.dataPaginationStore.remotePage ||
      reload
    ) {
      this.dataPaginationStore.remotePage = pageMapper.remotePage;
      this.getAll$(activeGrid, filter).subscribe();
    }
  }

  getAll$(
    viewId?: string | undefined,
    filter?: DealRemoteFilter
  ): Observable<GridDataIndexResponseType[] | GridDataResponse> {
    this.uiStore.dataLoadingState = true;
    const route =
      this.authorizationService.isSubscribed() &&
      !this.authorizationService.isDemo()
        ? ApiRoutes.DEAL_DATA_GRID
        : ApiRoutes.DEAL_GRID_PUBLIC;

    const url = route + (viewId ? `/${viewId}` : '');

    console.debug('FETCHING DEAL DATA');

    this.dealDataGridFilterService.dataSheetStorageService.gridDisplayData = [];

    return this.api
      .httpPost$<GridDataResponse>(
        `${url}?page=${this.dataPaginationStore.remotePage}&limit=${this.dataPaginationStore.dataSetSize}`,
        filter
      )
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            this.storageService.delete(AuthKeysEnum.USER_DATA_KEY);
            this.router.navigate([ApiRoutes.DATA_GRID_PUBLIC]).then(() => {});
          }

          return throwError(() => err);
        }),

        tap(val => {
          this.dataPaginationStoreService.length = val.totalRecords;

          const data = this._processGrid(val);

          this.dealDataGridFilterService.dataSheetStorageService.gridDisplayData = [
            ...data
          ];
        }),
        tap(() => (this.uiStore.dataLoadingState = false)),
        tap(() => (this.dealRemoteFilterStore.dealRemoteSearchTrigger = false))
      );
  }

  private _processGrid(val: GridDataResponse) {
    const record = val.record;
    const headers = val.headers;
    const view = val.view;
    const data = val.data;
    const summary = val.summary;

    this.dealDataGridFilterService.dataSheetStorageService.gridRecord = record;

    this.dealDataGridFilterService.dataSheetStorageService.gridHeader = headers;
    this.dataCellService.setCellWidth(headers);
    this.dealDataGridFilterService.dataSheetStorageService.fieldMap = this.dataCellService.setFilterRefs(
      headers
    );

    this.dealDataGridFilterService.filterStorageService.activeGridView = view;

    this.dealDataGridFilterService.dataSheetStorageService.gridData = data;

    this.gridSummaryStore.summary = summary;

    return data;
  }
}
