import { Injectable } from '@angular/core';
import { Observable, tap, throwError } from 'rxjs';
import { GridDataResponse } from 'src/app/data-sheet/data/type/grid/grid-data-response.type';
import { ApiRoutes } from '../../../../common/data/enum/routes.enum';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../../../common/service/api.service';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { StorageService } from '../../../../common/service/storage.service';
import { Router } from '@angular/router';
import { FilterRefEnum } from '../../../data/enum/grid/filter-ref.enum';
import { UIOptionType } from '../../../data/type/filter/UIOption.type';
import { PublicDataGridFilterService } from '../../public-data-grid-filter.service';
import { DataCellService } from '../../data-cell.service';
import { FilterTriggerEvent } from '../../FilterTriggerEvent';
import { AuthKeysEnum } from '../../../../auth/data/enum/auth-keys.enum';

@Injectable({
  providedIn: 'root'
})
export class PublicDataService {
  constructor(
    private gridFilterService: PublicDataGridFilterService,
    private dataCellService: DataCellService,
    private authorizationService: AuthorizationService,
    private filterTriggerEvent: FilterTriggerEvent,
    private api: ApiService,
    private storageService: StorageService,
    private router: Router
  ) {}

  getAll$(viewId?: string | undefined): Observable<GridDataResponse> {
    const route =
      this.authorizationService.isSubscribed() &&
      !this.authorizationService.isDemo()
        ? ApiRoutes.DATA_GRID
        : ApiRoutes.DATA_GRID_PUBLIC;

    const url = route + (viewId ? `/${viewId}` : '');

    console.debug('FETCHING PUBLIC DATA');

    return this.api.httpGet$<GridDataResponse>(url).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.storageService.delete(AuthKeysEnum.USER_DATA_KEY);
          this.router.navigate([ApiRoutes.DATA_GRID_PUBLIC]).then(() => {});
        }

        return throwError(() => err);
      }),

      tap(val => {
        this._processGrid(val);
      }),
      tap(() => {
        this.filterTriggerEvent.triggerPublicFilter.next(true);
      })
    );
  }

  private _processGrid(val: GridDataResponse) {
    this.gridFilterService.dataSheetStorageService.gridRecord = val.record;

    this.gridFilterService.dataSheetStorageService.gridHeader = val.headers;
    this.dataCellService.setCellWidth(val.headers);
    this.gridFilterService.dataSheetStorageService.fieldMap = this.dataCellService.setFilterRefs(
      val.headers
    );

    this.gridFilterService.filterStorageService.activeGridView = val.view;

    this.gridFilterService.dataSheetStorageService.gridData = val.data;

    const skuRef = <string>(
      this.gridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.SKU
      )
    );

    const nameRef = <string>(
      this.gridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.COMPANY
      )
    );

    const tagsRef = <string>(
      this.gridFilterService.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.VERTICALS
      )
    );

    this.gridFilterService.filterStorageService.organizations = val.data
      .map(
        it =>
          <UIOptionType>{
            index: skuRef != undefined ? it[skuRef] : '',
            label: nameRef != undefined ? it[nameRef] : '',
            ref: skuRef != undefined ? it[skuRef] : '',
            meta: tagsRef != undefined ? it[tagsRef]?.toString().split(';') : []
          }
      )
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }
}
