import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridView } from '../../../data/type/grid/grid-view.type';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SnackBarService } from '../../../../uicommon/service/snack-bar.service';
import { PublicDataGridFilterService } from '../../../service/public-data-grid-filter.service';
import { AccessPromoService } from '../../../../auth/services/access-promo.service';
import { SnackMessageStatusEnum } from '../../../../uicommon/data/enum/snackMessageStatus.enum';
import { PublicDataExportService } from '../../../service/data-sheet/public-data/public-data-export.service';
import { PromoEnum } from '../../../../auth/data/enum/promo.enum';
import { DealDataExportService } from '../../../service/data-sheet/deal-data/deal-data-export.service';
import { Subscription } from 'rxjs';
import { NavigationStore } from '../../../../navigation/service/store/navigation.store';
import { NavigationItemType } from '../../../../navigation/data/type/navigation-item.type';
import { DataType } from '../../../../navigation/data/emun/data-type';

@Component({
  selector: 'mul-download-data-action',
  templateUrl: './download-data-action.component.html'
})
export class DownloadDataActionComponent implements OnDestroy {
  private gridViewsSubscription: Subscription = new Subscription();
  private navigationStateSubscription: Subscription = new Subscription();

  navigationState: NavigationItemType = {} as NavigationItemType;

  gridViews: GridView[] = [];
  downloadActionState = false;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private readonly gridFilterService: PublicDataGridFilterService,
    public readonly authorizationService: AuthorizationService,
    private readonly spinner: NgxSpinnerService,
    private readonly $gaService: GoogleAnalyticsService,
    private readonly publicDataExportService: PublicDataExportService,
    private readonly dealDataExportService: DealDataExportService,
    private readonly snackBarService: SnackBarService,
    private readonly accessPromoService: AccessPromoService,
    private readonly navigationStore: NavigationStore
  ) {
    this.gridViewsSubscription = this.gridFilterService.filterStorageService.gridViews$.subscribe(
      val => {
        this.gridViews = val;
      }
    );

    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.navigationState = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.navigationStateSubscription.unsubscribe();
    this.gridViewsSubscription.unsubscribe();
  }

  export() {
    if (
      !this.authorizationService.isSubscribed() ||
      this.authorizationService.isDemo()
    ) {
      this.accessPromoService.show(PromoEnum.DOWNLOAD);
      return;
    }

    const viewsIds = this.gridViews.map(it => it.id).join(',');
    this.spinner?.show('download');
    this.downloadActionState = true;

    this.$gaService.event('download_data');

    const exportService: PublicDataExportService | DealDataExportService =
      this.navigationState.dataType === DataType.PUBLIC
        ? this.publicDataExportService
        : this.dealDataExportService;

    exportService.export$(viewsIds).subscribe({
      next: blob => {
        const now = formatDate(Date.now(), 'yyyy-MM-dd', this.locale);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `multiples-finance-${now}.xlsx`;
        link.click();
        this.spinner?.hide('download');
        this.downloadActionState = false;
      },
      error: err => {
        const message =
          err.status === 412
            ? 'You have reached your monthly limit download quota.'
            : 'Unexpected download error';
        this.snackBarService.snack({
          type: SnackMessageStatusEnum.ERROR,
          message
        });
        this.spinner?.hide('download');
        this.downloadActionState = false;
      }
    });
  }
}
