import { Component, OnDestroy, OnInit } from '@angular/core';
import { GridView } from '../../data/type/grid/grid-view.type';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { PublicDataGridFilterService } from '../../service/public-data-grid-filter.service';
import { AccessPromoService } from '../../../auth/services/access-promo.service';
import { PublicDataService } from '../../service/data-sheet/public-data/public-data.service';
import { Subscription } from 'rxjs';
import { PromoEnum } from '../../../auth/data/enum/promo.enum';
import { NavigationStore } from '../../../navigation/service/store/navigation.store';
import { NavigationItemType } from '../../../navigation/data/type/navigation-item.type';
import { DataType } from '../../../navigation/data/emun/data-type';
import { DealDataService } from '../../service/data-sheet/deal-data/deal-data.service';
import { DataPaginationStore } from '../../service/store/data-pagination.store';
import { DealRemoteFilterStore } from '../../service/store/deal-remote-filter.store';

@Component({
  selector: 'mul-data-grid-view-selector',
  templateUrl: './data-grid-view-selector.component.html'
})
export class DataGridViewSelectorComponent implements OnInit, OnDestroy {
  gridViews: GridView[] = [];
  activeGridView = '';
  navigationState: NavigationItemType = {} as NavigationItemType;
  loading: string | null = null;

  private gridViewsSubscriptions: Subscription = new Subscription();
  private activeGridViewSubscriptions: Subscription = new Subscription();
  private navigationStateSubscription: Subscription = new Subscription();

  constructor(
    private readonly publicDataService: PublicDataService,
    private readonly dealDataService: DealDataService,
    private dataPaginationStore: DataPaginationStore,
    private dealRemoteFilterStore: DealRemoteFilterStore,
    private readonly gridFilterService: PublicDataGridFilterService,
    private readonly authorizationService: AuthorizationService,
    private readonly accessPromoService: AccessPromoService,
    private readonly navigationStore: NavigationStore,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.gridViewsSubscriptions = this.gridFilterService.filterStorageService.gridViews$.subscribe(
      val => {
        this.gridViews = val;
      }
    );

    this.activeGridViewSubscriptions = this.gridFilterService.filterStorageService.activeGridView$.subscribe(
      val => {
        this.activeGridView = val;
      }
    );

    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.navigationState = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.activeGridViewSubscriptions.unsubscribe();
    this.gridViewsSubscriptions.unsubscribe();
    this.navigationStateSubscription.unsubscribe();
  }

  changeView(id: string) {
    if (
      !this.authorizationService.isSubscribed() ||
      this.authorizationService.isDemo()
    ) {
      this.accessPromoService.show(PromoEnum.VIEWS);
      return;
    }

    this.loading = id;
    this.gridFilterService.filterStorageService.activeGridView = id;

    this.spinner?.show(`view-${id}`);
    this.spinner?.show('global');

    if (this.navigationState.dataType === DataType.PUBLIC) {
      this.publicDataService.getAll$(id).subscribe({
        next: () => {
          this.spinner?.hide(`view-${id}`).then(() => {
            this.loading = null;
          });
        }
      });
    } else if (this.navigationState.dataType === DataType.DEALS) {
      this.dataPaginationStore.page = 1;
      const filter = this.dealRemoteFilterStore.dealRemoteFilter;

      this.dealDataService.getAll$(id, filter).subscribe({
        next: () => {
          this.spinner?.hide(`view-${id}`).then(() => {
            this.loading = null;
          });
        }
      });
    }
  }
}
