import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { switchMap } from 'rxjs';
import { SubscriptionService } from '../../services/subscription.service';
import { SnackBarService } from '../../../uicommon/service/snack-bar.service';
import { StripeService } from 'ngx-stripe';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { SnackMessageStatusEnum } from '../../../uicommon/data/enum/snackMessageStatus.enum';
import { AuthService } from '../../../auth/services/auth.service';
import { AuthKeysEnum } from '../../../auth/data/enum/auth-keys.enum';

@Component({
  selector: 'mul-upgrade-action',
  templateUrl: './upgrade-action.component.html'
})
export class UpgradeActionComponent {
  @Input() label = 'Upgrade Plan';
  public env = environment;

  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private snackBarService: SnackBarService,
    private stripeService: StripeService,
    private spinner: NgxSpinnerService
  ) {}

  manageSubscriptionUpgrade() {
    if (this.authorizationService.isDemo()) {
      this.authService.doLogOut().subscribe(() => {
        this.authService.authUserStore.user = null;
        this.subscriptionService.handleSubscriptionChange(null);

        this.authService.storageService.delete(AuthKeysEnum.USER_DATA_KEY);

        if (this.authService.logOutTimer) {
          clearTimeout(this.authService.logOutTimer);
        }

        parent.window.location.href = this.env.site_url + '/pricing';
      });
    } else if (this.authorizationService.hasSubscription()) {
      this._goToCustomerPortal();
    } else {
      this._onSubscriptionUpgrade(this.env.default_price);
    }
  }

  private _goToCustomerPortal() {
    this.spinner?.show('global');
    this.subscriptionService.createCustomerSession().subscribe(res => {
      window.location.href = res.url;
    });
  }

  private _onSubscriptionUpgrade(priceId: string) {
    this.spinner?.show('global');

    this.subscriptionService.createAccountSubscription(priceId).subscribe({
      next: res => {
        if (res.subscription) {
          this._createCheckoutSession(
            res.accountId,
            res.subscription.customerId,
            priceId
          );
        }
      },
      error: err => {
        this.spinner?.hide('global');
        this.snackBarService.snack({
          type: SnackMessageStatusEnum.ERROR,
          message: err.message
        });
      }
    });
  }

  private _createCheckoutSession(
    accountId: string,
    customerId: string,
    priceId: string
  ) {
    this.subscriptionService
      .createCheckoutSession({
        accountId: accountId,
        priceId: priceId,
        customerId: customerId
      })
      .pipe(
        switchMap(res =>
          this.stripeService.redirectToCheckout({
            sessionId: res.sessionId
          })
        )
      )
      .subscribe({
        error: err => {
          this.spinner?.hide('global');
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.ERROR,
            message: err.message
          });
          console.error(err);
        }
      });
  }
}
