import { Component, effect } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AccessPromoService } from '../../services/access-promo.service';
import { PromoEnum } from '../../data/enum/promo.enum';
import { PromoType } from '../../data/type/promo.type';
import { AuthorizationService } from '../../../common/service/authorization.service';

@Component({
  selector: 'mul-access-promo',
  templateUrl: './access-promo.component.html'
})
export class AccessPromoComponent {
  env = environment;
  promoType: PromoEnum | null = null;
  selectPromo: PromoType = {} as PromoType;

  headerPrefix = this.isDemo()
    ? 'Sign up for Multiples.vc to'
    : 'Upgrade your account to';

  promos: PromoType[] = [
    {
      type: PromoEnum.FILTER,
      title: this.headerPrefix + ' access filters',
      subtitle:
        'Filter through 220+ verticals like B2B marketplaces, defense tech, IoT and mode',
      image: '/assets/media/promo/filters.jpg'
    },
    {
      type: PromoEnum.VIEWS,
      title: this.headerPrefix + ' access more metrics',
      subtitle:
        'Benchmark metrics like rule of 40, growth-adjusted PEG or R&D to revenue ratio',
      image: '/assets/media/promo/views.png'
    },
    {
      type: PromoEnum.DOWNLOAD,
      title: this.headerPrefix + ' download data',
      subtitle:
        'Download your data set to an Excel file and build custom analyses',
      image: '/assets/media/promo/download.png'
    },
    {
      type: PromoEnum.SHARED,
      title: this.headerPrefix + ' share views',
      subtitle:
        'Share your findings with anyone, even if they don’t have a Multiples account',
      image: '/assets/media/promo/share.png'
    },
    {
      type: PromoEnum.SAVE,
      title: this.headerPrefix + ' save your search',
      subtitle: 'Save your custom data set and access always up-to-date comps',
      image: '/assets/media/promo/save-search.jpg'
    }
  ];

  constructor(
    private readonly accessPromoService: AccessPromoService,
    private readonly authorizationService: AuthorizationService
  ) {
    effect(() => {
      this.promoType = this.accessPromoService.dialog();

      if (this.promoType) {
        this.selectPromo = this.promos.find(it => it.type === this.promoType)!;
      }
    });
  }

  dismiss() {
    this.accessPromoService.hide();
  }

  isDemo() {
    return this.authorizationService.isDemo();
  }
}
