import { Component, effect, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoredGridService } from '../../../service/stored-grid.service';
import { AccessPromoService } from '../../../../auth/services/access-promo.service';
import { UiObserverStore } from '../../../../uicommon/service/ui-observer.store';
import { UiComponentRegisterEnum } from '../../../../uicommon/data/enum/uiComponentRegister.enum';
import { PromoEnum } from '../../../../auth/data/enum/promo.enum';
import { NavigationItemType } from '../../../../navigation/data/type/navigation-item.type';
import { NavigationStore } from '../../../../navigation/service/store/navigation.store';
import { Subscription } from 'rxjs';
import { DataType } from '../../../../navigation/data/emun/data-type';
import { StoredGridEnum } from '../../../data/enum/stored-grid/stored-grid.enum';

@Component({
  selector: 'mul-save-data-action',
  templateUrl: './save-data-action.component.html'
})
export class SaveDataActionComponent implements OnDestroy {
  navigationStateSubscription: Subscription = new Subscription();
  navigationState: NavigationItemType = {} as NavigationItemType;

  saveActionState = false;

  savedSearchForm: FormGroup = this.formBuilder.group({
    name: [null, [Validators.required]]
  });

  public displaySaveDialog = false;

  constructor(
    public readonly authorizationService: AuthorizationService,
    private readonly storedGridFilterService: StoredGridService,
    private readonly accessPromoService: AccessPromoService,
    private readonly formBuilder: FormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly navigationStore: NavigationStore,
    private readonly uiObserverService: UiObserverStore
  ) {
    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.navigationState = val;
      }
    );

    effect(() => {
      if (
        this.uiObserverService.uiFocusObserver !==
        UiComponentRegisterEnum.SELECT_SAVED_SEARCH_FORM
      ) {
        this.displaySaveDialog = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.navigationStateSubscription.unsubscribe();
  }

  toggle() {
    if (
      !this.authorizationService.isSubscribed() ||
      this.authorizationService.isDemo()
    ) {
      this.accessPromoService.show(PromoEnum.SAVE);
      return;
    }

    this.displaySaveDialog = !this.displaySaveDialog;

    if (this.displaySaveDialog) {
      this.uiObserverService.uiFocusObserver =
        UiComponentRegisterEnum.SELECT_SAVED_SEARCH_FORM;
    }
  }

  close() {
    this.displaySaveDialog = false;
  }

  save() {
    if (this.savedSearchForm.valid) {
      this.saveActionState = true;
      this.spinner?.show('save');

      const name = this.savedSearchForm.get('name')?.value;

      const type =
        this.navigationState.dataType === DataType.PUBLIC
          ? StoredGridEnum.PUBLIC
          : StoredGridEnum.DEAL;

      this.storedGridFilterService.save$(type, name, true).subscribe(data => {
        this.storedGridFilterService.storedGridStore.addToSavedStoredGrids(
          data
        );

        this.spinner?.hide('save');
        this.saveActionState = false;

        this._reset();

        this.displaySaveDialog = false;
      });
    }
  }

  private _reset() {
    this.savedSearchForm.get('name')?.patchValue(null);
    this.savedSearchForm.reset();
  }
}
